import React from 'react';
import {useEffect, useState} from 'react';
import {useMediaQuery} from 'react-responsive';
import emailjs from '@emailjs/browser';
import {motion, useAnimation} from 'framer-motion';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4'

//Icons
import { HiMenu } from 'react-icons/hi';

//CSS
import '../css/ContactUs.css'

//Components
import SiteMap from '../Components/SiteMap';
import MobileNavigationWindow from '../Components/MobileNavigationWindow';

//Images
import initialsLogo from '../images/TPSLogo+Initials.png'
import envelopeAddress from '../images/envelopeMailAddress.png'

function ContactUs() {

    const navModalAnim = useAnimation();
    const [navModal, setNavModal] = useState(false);
    ReactGA.initialize("G-SZLZLTXLRV");

    useEffect(()=>{
        ReactGA.send({
            hitType: "pageview",
            page: "/",
            title: "Contact Us"
        });
        
        let options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.65
        };

        emailjs.init('zLZlONpT1kLop60cT');

        navModalAnim.start("visible");

        function callbackFunc(entries, observerFour){
            entries.forEach(entry => {
                switch(entry.target.id){
                    case "address-container":
                        if(entry.isIntersecting){
                            document.getElementById("address-container").classList.remove("hidden-container");
                            document.getElementById("address-container").classList.add("fadeIn-envelope");
                        }
                        break;
                    case "address-title":
                        if(entry.isIntersecting){
                            document.getElementById("address-title").classList.remove("hidden-container");
                            document.getElementById("address-title").classList.add("fadeIn-title");
                        }
                        break;
                    default:
                        break;
                }
            });
        }

        let observer = new IntersectionObserver(callbackFunc, options);

        const allElements = document.querySelectorAll('.hidden-container');

        allElements.forEach((element)=>{observer.observe(element);});

    }, [navModalAnim]);

    const isMobile = useMediaQuery({
        query: '(max-width: 700px)'
    })

    const handleSubmit = (e) =>{

        let btn = document.getElementById("submit-button")

        if(btn == null){
            btn = document.getElementById("submit-button-mobile");
        }

        e.preventDefault();

        btn.value = 'Sending...';

        const serviceID = 'service_k5y3j2c';
        const templateID = 'template_kkubyzj';

        emailjs.sendForm(serviceID, templateID, e.target)
            .then(() => {
                btn.value = 'Send Email';
                alert('Your message was sent to the TPS Team!');
            
                let from, email, msg;

                if(!isMobile){
                    from = document.getElementById("from_name");
                    email = document.getElementById("from_email");
                    msg = document.getElementById("message");
                }
                else{
                    from = document.getElementById("from_name-mobile");
                    email = document.getElementById("from_email-mobile");
                    msg = document.getElementById("message-mobile");
                }

                from.value = "";
                email.value = "";
                msg.value = "";        

                return;

        }, (err) => {
            btn.value = 'Send Email';
            alert("There was a problem sending a message to our team. Please try again or send us an email directly at: contact@tps-technology.com");

            ReactGA.event({
                category: "contact_form_error",
                action: `Contact form failed to submit: ${err}`,
            });

            return;
        });

    }

    const naval = {visible: {opacity: [0,1]}, hidden: {opacity: [1, 0], transition: {duration: 0.5}}}

    function showHideModal(){
        if(navModal){
            navModalAnim.start("hidden").then(()=>{
                setNavModal(false);
            });
        }
        else{
            setNavModal(true);
            navModalAnim.start({x: 500});
        }
    }

    return (
        <div>
            <header>
                {!isMobile && 
                    <nav className={"nav-desktop"}>
                        <Link to={"/"} id="nav-logo-desktop">
                            <img alt="Transparent Software Logo" src={initialsLogo}/>
                        </Link>
                        <div id={"nav-links-desktop"}>
                            <motion.a whileHover={{color: ['#000000', '#508B68'], y: [0, -2]}} href="/#explore"><p>Our Products</p></motion.a>
                            <motion.a whileHover={{color: ['#000000', '#508B68'], y: [0, -2]}} href="/#whatweoffer"><p>Services</p></motion.a>
                            <motion.a whileHover={{color: ['#000000', '#508B68'], y: [0, -2]}} href="/#whatwedo"><p>What We Do</p></motion.a>
                            <motion.a whileHover={{color: ['#000000', '#508B68'], y: [0, -2]}} href="/contactus"><p>Contact Us</p></motion.a>
                            <Link to={"/store"} id="nav-contact-desktop">
                                <motion.button id="nav-contact-button" whileHover={{backgroundColor: '#FFBE55'}}>
                                    <p style={{color: "#F2F4F3"}}>Shop</p>
                                </motion.button>
                            </Link>
                        </div>
                    </nav>
                }
                {isMobile && 
                    <nav className={"nav-mobile"}>
                        <Link to={"/"} id="logo-link-container">
                            <img src={initialsLogo} alt="Transparent Software Logo" id="nav-logo-mobile"/>
                        </Link>
                        <HiMenu id={"hamburger-icon-mobile"} size={25} onClick={()=>showHideModal()}/>
                    </nav>
                }
            </header>
            {isMobile && navModal && 
                <MobileNavigationWindow naval={naval} navModalAnim={navModalAnim}/>
            }
            <div className={isMobile ? "parent-container-mobile" : "parent-container"} id={!isMobile ? "section-six-background" : undefined}>
                <h2 id="help-form-header" style={{fontSize: isMobile ? "8vw" : undefined}} >Reach out to our <span style={{color: '#78A48A'}}>team.</span></h2>
                <form id={isMobile ? "help-form-mobile" : "help-form"} onSubmit={handleSubmit}>
                <div class={isMobile ? "name-email-container-mobile" : "name-email-container"}>

                    <label id={isMobile ? "name-label-mobile" : "name-label"} for="from_name">Name: </label>
                    <input type="text" name="from_name" id={isMobile ? "from_name-mobile" : "from_name"} placeholder="John Doe"/>

                    <label id={isMobile ? "email-label-mobile" : "email-label"} for="from_email">Email Address:</label>
                    <input type="text" name="from_email" id={isMobile ? "from_email-mobile" : "from_email"} placeholder="doe.john@gmail.com"/>

                </div>

                <label id={isMobile ? "message-label-mobile" : "message-label"} for="message" style={{marginTop: isMobile ? 15 : 25,}}>Message to the TPS team:</label>
                <textarea type="text" name="message" id={isMobile ? "message-mobile" : "message"} placeholder='Please include a detailed message of why you are reaching out and how we can help...'/>

                <motion.input whileHover={{opacity:[1, 0.75]}} type="submit" id={isMobile ? "submit-button-mobile" : "submit-button"} value="Send Email"/>
                </form>
            </div>
            <h3 style={{fontSize: isMobile ? "5vw" : undefined, paddingBlock: isMobile ? '10%' : 0, marginBlock: 0, textAlign: 'center', width: isMobile ? '80%' : undefined, marginLeft: 'auto', marginRight: 'auto'}} id={"address-title"} className={"hidden-container"}>or contact us at the following address:</h3>   
            <div id="address-container" className={isMobile ? "address-container-mobile hidden-container" : "address-container-desktop hidden-container"}>
                    <img id={isMobile ? 'envelope-mobile' : 'envelope-desktop'} src={envelopeAddress} alt={"Envelope with stamp in the top right"}></img>
                    <address id={isMobile ? "address-mobile" : "address-desktop"}>Transparent Software LLC<br/>13046 Racetrack Road<br/>PMB 258<br/>Tampa, FL, 33626</address>
            </div>
            <footer id={isMobile ? "footer-container-mobile" : "footer-container-desktop"}>
                    <SiteMap isMobile={isMobile}/>
            </footer>
        </div>
    )
}

export default ContactUs