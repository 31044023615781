import React from 'react'
import {useEffect, useState} from 'react';
import {useMediaQuery} from 'react-responsive';
import {motion, useAnimation} from 'framer-motion';
import { Link } from 'react-router-dom';
import Collapsible from "react-collapsible";
import ReactGA from 'react-ga4'

//Icons
import { HiMenu } from 'react-icons/hi';

//CSS
import '../css/FAQ.css'

//Images
import initialsLogo from '../images/TPSLogo+Initials.png'
import tpsLogo from '../images/TPSLogo.png'
import tpsFullLogo from '../images/TPSLogo+Name.png'
import downArrow from '../images/downArrow.png'
import clashMarkerLogo from '../images/constellationItemsIcon.jpg'
import mindfulLogo from '../images/mindfulPurchaseHeader.jpg'
import MobileNavigationWindow from '../Components/MobileNavigationWindow';
import SiteMap from '../Components/SiteMap';

function FAQ() {

    const [isLoaded, setLoading] = useState(false);
    const loadingModal = useAnimation();
    const navModalAnim = useAnimation();
    const [navModal, setNavModal] = useState(false);
    ReactGA.initialize("G-SZLZLTXLRV");

    const isMobile = useMediaQuery({
        query: '(max-width: 700px)'
    })

    useEffect(()=>{

        ReactGA.send({
            hitType: "pageview",
            page: "/",
            title: "FAQ"
        });

        const body = document.body;
        const previousOverflow = body.style.overflow;
        const previousPaddingRight = body.style.paddingRight;
    
        loadingModal.start("visible");
        navModalAnim.start("visible");

        var image = document.getElementById("faq-title-image");

        const scrollbarWidth = window.innerWidth - document.body.offsetWidth;
        document.body.style.paddingRight = `${scrollbarWidth}px`;
        document.body.style.overflowY = "hidden";

        image.onload = function (){
            setTimeout(()=>{
            loadingModal.start("hidden").then(()=>{
                document.body.style.overflowY = "scroll";
                document.body.style.paddingRight = 0;
            });
            }, 1500);
        };
    
        body.style.overflow = previousOverflow;
        body.style.paddingRight = previousPaddingRight;
    
    }, [loadingModal, navModalAnim])

    const loadal = {visible: {opacity: [0,1]}, hidden: {opacity: [1, 0], transition: {duration: 1}}}
    const naval = {visible: {opacity: [0,1]}, hidden: {opacity: [1, 0], transition: {duration: 0.5}}}

    function onUpdate(latest){
        if(latest.opacity === 0){
            setLoading(true);
        }
    }

    function showHideModal(){
        if(navModal){
            navModalAnim.start("hidden").then(()=>{
                setNavModal(false);
            });
        }
        else{
            setNavModal(true);
            navModalAnim.start({x: 500});
        }
    }

    function rotateArrow(id) {
        switch(id){
            case 1:
                if(isMobile){
                    if (document.getElementById("drop-down-arrow-mobile").style.rotate === "180deg") {
                        document.getElementById("drop-down-arrow-mobile").style = `rotate:0deg; width: ${isMobile ? "8%" : "3%"}; cursor: pointer;`;
                    }
                    else {
                        document.getElementById("drop-down-arrow-mobile").style = `rotate:180deg; width: ${isMobile ? "8%" : "3%"}; cursor: pointer;`;
                    }
                }
                else{
                    if (document.getElementById("drop-down-arrow").style.rotate === "180deg") {
                        document.getElementById("drop-down-arrow").style = `rotate:0deg; width: ${isMobile ? "8%" : "3%"}; cursor: pointer;`;
                    }
                    else {
                        document.getElementById("drop-down-arrow").style = `rotate:180deg; width: ${isMobile ? "8%" : "3%"}; cursor: pointer;`;
                    }
                }
                break
            case 2:
                if(isMobile){
                    if (document.getElementById("drop-down-arrow-two-mobile").style.rotate === "180deg") {
                        document.getElementById("drop-down-arrow-two-mobile").style = `rotate:0deg; width: ${isMobile ? "8%" : "3%"}; cursor: pointer;`;
                    }
                    else {
                        document.getElementById("drop-down-arrow-two-mobile").style = `rotate:180deg; width: ${isMobile ? "8%" : "3%"}; cursor: pointer;`;
                    }
                }
                else{
                    if (document.getElementById("drop-down-arrow-two").style.rotate === "180deg") {
                        document.getElementById("drop-down-arrow-two").style = `rotate:0deg; width: ${isMobile ? "8%" : "3%"}; cursor: pointer;`;
                    }
                    else {
                        document.getElementById("drop-down-arrow-two").style = `rotate:180deg; width: ${isMobile ? "8%" : "3%"}; cursor: pointer;`;
                    }
                }
                break
            default:
                break
        }
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', flex: 1, overflowY: 'scroll'}}>
            {!isLoaded && 
                <motion.div animate={loadingModal} variants={loadal} id="loadingCover" onUpdate={onUpdate}>
                <motion.img src={tpsFullLogo} id={isMobile ? "loading-cover-img-mobile" : "loading-cover-img"} animate={{opacity: [0, 1], transition: {repeat: Infinity, duration: 1.75, repeatType: "reverse"}}}/>
                </motion.div>
            }
            <header>
                {!isMobile && 
                    <nav className={"nav-desktop"}>
                        <Link to={"/"} id="nav-logo-desktop">
                            <img alt="Transparent Software Logo" src={initialsLogo}/>
                        </Link>
                        <div id={"nav-links-desktop"}>
                            <motion.a whileHover={{color: ['#000000', '#508B68'], y: [0, -2]}} href="/#explore"><p>Our Products</p></motion.a>
                            <motion.a whileHover={{color: ['#000000', '#508B68'], y: [0, -2]}} href="/#whatweoffer"><p>Services</p></motion.a>
                            <motion.a whileHover={{color: ['#000000', '#508B68'], y: [0, -2]}} href="/#whatwedo"><p>What We Do</p></motion.a>
                            <motion.a whileHover={{color: ['#000000', '#508B68'], y: [0, -2]}} href="/contactus"><p>Contact Us</p></motion.a>
                            <Link to={"/store"} id="nav-contact-desktop">
                                <motion.button id="nav-contact-button" whileHover={{backgroundColor: '#FFBE55'}}>
                                    <p style={{color: "#F2F4F3"}}>Shop</p>
                                </motion.button>
                            </Link>
                        </div>
                    </nav>
                }
                {isMobile && 
                    <nav className={"nav-mobile"}>
                        <Link to={"/"} id="logo-link-container">
                            <img src={initialsLogo} alt="Transparent Software Logo" id="nav-logo-mobile"/>
                        </Link>
                        <HiMenu id={"hamburger-icon-mobile"} size={25} onClick={()=> showHideModal()}/>
                    </nav>
                }
            </header>
            {isMobile && navModal && 
                <MobileNavigationWindow naval={naval} navModalAnim={navModalAnim}/>
            }
            <div id="main-container" style={{paddingTop: isMobile ? '8vh' : undefined, overflow: 'scroll', }}>
                <div className={isMobile ? "faq-title-container-mobile" : "faq-title-container-desktop"}>
                    <div id={isMobile ? "faq-title-container-bg-mobile" : "faq-title-container-bg-desktop"}>
                        <svg id="slanted-artwork" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 483" fill="none" preserveAspectRatio='none'>
                            <path d="M0 0H1440V339L0 483V0Z" fill="#A2BFAE"/>
                        </svg>
                    </div>
                    <div className={isMobile ? "faq-text-image-container-mobile" : "faq-text-image-container-desktop"}>
                        <h1>Frequently Asked Questions</h1>
                        <img src={tpsLogo} id="faq-title-image" alt="TPS Logo featuring gears behind a piece of glass"/>
                    </div>
                </div>
                <div className={isMobile ? "faq-products-container-mobile" : "faq-products-container-desktop"}>
                    <Collapsible triggerDisabled={false} trigger={<div className={isMobile ? "faq-product-container-mobile" : "faq-product-container-desktop"}><div style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',}}><img alt="Clash Marker Logo" className={isMobile ? "faq-icon-mobile" : "faq-icon-desktop"} src={clashMarkerLogo}/><h3>Clash Marker</h3></div><img id={isMobile ? "drop-down-arrow-mobile" : "drop-down-arrow"} alt="Downward Facing Arrow" src={downArrow} onClick={()=>{rotateArrow(1)}}/></div>} easing={"ease-in-out"}>
                        <div className={isMobile ? "faq-item-container-mobile" : "faq-item-container-desktop"}>
                            <h4>How can I purchase Clash Marker?</h4>
                            <p>Clash Marker can be purchased in the TPS Store <Link style={{color: '#508B68'}} to={"/store"}>here</Link>. It is a subscription based payment plan that offers monthly or yearly renewal.</p>
                        </div>
                        <div className={isMobile ? "faq-item-container-mobile" : "faq-item-container-desktop"}>
                            <h4>I purchased Clash Marker, how do I download it?</h4>
                            <p>Once you have purchased Clash Marker, you can find a download link available to you on your TPS Store profile page <Link style={{color: '#508B68'}} to={"/store/profile"}>here</Link>. If not already, sign into your profile to view your profile information. Scroll down on your profile page until you see the <strong>Downloadables</strong> section, here you will see all of your purchased product's download links. Under the <strong>Previous Orders</strong> section, find the order number tied to your Clash Marker purchase and click the <span style={{color: '#508B68'}}>View</span> button. Here you will find the license keys you were provisioned upon purchasing Clash Marker that will be needed to activate the software on your computer.</p>
                        </div>
                        <div className={isMobile ? "faq-item-container-mobile" : "faq-item-container-desktop"}>
                            <h4>Where are the Clash Marker plug-ins?</h4>
                            <p>The Navisworks and Revit plug-in for Clash Marker are automatically installed for you when you run the Clash Marker installer. They are installed at the respective plug-in locations for both Navisworks and Revit. It will automatically install every version from 2022 to 2025 (the versions currently supported by Autodesk).</p>
                        </div>
                        <div className={isMobile ? "faq-item-container-mobile" : "faq-item-container-desktop"}>
                            <h4>Will Clash Maker work with Viewpoint Reports and Clash Reports?</h4>
                            <p>Yes, both viewpoint and clash reports can be imported into Clash Marker. Both report types will be marked onto your 2D CAD file and/or 3D model. With Viewpoint reports, the names of each of the viewpoints are saved in a file for you to reference. Any notes a coordinator may have put in the viewpoint name will be easily accessible to you.</p>
                        </div>
                        <div className={isMobile ? "faq-item-container-mobile" : "faq-item-container-desktop"}>
                            <h4>What is the custom origin point for in Clash Marker?</h4>
                            <p>Clash Marker has this feature for Navisworks models that are not transformed from the 0,0,0 origin. You can specify the transform your Navisworks file is using and Clash Marker will automatically adjust its marking accordingly so that everything is marked in your design suite accurately.</p>
                        </div>
                        <div className={isMobile ? "faq-item-container-mobile" : "faq-item-container-desktop"}>
                            <h4>What is the custom rotation for in Clash Marker?</h4>
                            <p>This feature is for Navisworks models that are rotated a certain degree about the 0,0,0 origin. If your model is rotated a specific amount, Clash Marker needs this value so that it can properly calculate the unrotated location of any clashes or viewpoints so that they can be marked on your model/drawing accurately.</p>
                        </div>
                        <div className={isMobile ? "faq-item-container-mobile" : "faq-item-container-desktop"}>
                            <h4>When I open Clash Marker its asking for an License Key, where do I find that?</h4>
                            <p>Your license key was provisioned to you upon purchasing Clash Marker. Your license keys can be found <Link style={{color: '#508B68'}} to={"/store/profile"}>here</Link> on your TPS Store profile. Find the order number attached to your Clash Marker purchase under the <strong>Previous Orders</strong> section and click the <span style={{color: '#508B68'}}>View</span> button. In this window, you will see your license key(s) under the <strong>Product Keys</strong> section. Simply copy your key from there and paste it into the Clash Marker program.</p>
                        </div>
                        <div className={isMobile ? "faq-item-container-mobile" : "faq-item-container-desktop"}>
                            <h4>How do I manage my Clash Marker subscription?</h4>
                            <p>Any subscriptions made on the TPS Store can be managed on your Stripe customer portal. To get to your customer portal, navigate to your profile page in the TPS Store <Link to={"/store/profile"}>here.</Link> At the bottom of the page, you will see a button to take you to your customer portal.</p>
                        </div>
                        <div className={isMobile ? "faq-item-container-mobile" : "faq-item-container-desktop"}>
                            <h4>Why do I keep running into error:  <span style={{color: '#fa3737', fontFamily: 'monospace'}}>COULD NOT FIND COMMAND BAR</span>?</h4>
                            <p>This is a specific error in Clash Marker that is informing you that it cannot find where your command bar is on your CAD program screen. Clash Marker programatically looks for the command bar so that it can automate the clash translation process. Here are some fixes you can try:</p>
                            <ol>
                                <li>Ensure that you have the command bar activated in your CAD software.</li>
                                <li>Ensure that there is nothing covering the command bar on your screen, like dialog boxes or other such windows.</li>
                            </ol>
                            <p>If you continue to run into this error and you tried the above fixes, please reach out to us <Link style={{color: '#508B68'}} to={"/contactus"}>here</Link> so we can personally help you solve your issue.</p>
                        </div>
                        <div className={isMobile ? "faq-item-container-mobile" : "faq-item-container-desktop"}>
                            <h4>I ran Clash Marker but nothing was drawn on my CAD file. Why?</h4>
                            <p>First its important to check the <strong>Status</strong> field in the Clash Marker UI. If a known error occured it will be printed out here and likely can be resolved on your own. In some cases there is a rare possibility that the execution order of Clash Marker's commands became jumbled. Often times, this issue is resolved by simply clicking <strong>Run</strong> again to retry the command execution.</p>
                        </div>
                        <div className={isMobile ? "faq-item-container-mobile" : "faq-item-container-desktop"}>
                            <h4>I ran Clash Marker and it tried to paste a block of text into my CAD file. Why?</h4>
                            <p>Clash Marker works by programatically entering the commands into the command bar inside your CAD program. If the mouse is moved or bumped while Clash Marker is executing commands, the commands execution will be affected and the text Clash Marker has saved to your clipboard may get pasted into your drawing by accident.</p>
                            <p>You can simply resolve this by deleting the text in your drawing, re-running your clashes in Clash Marker, and ensuring that the mouse is not moved while it operates.</p>
                        </div>
                        <div className={isMobile ? "faq-item-container-mobile" : "faq-item-container-desktop"}>
                            <h4>Do I have to use the Clash Marker Navisworks Plug-in to generate my viewpoint report?</h4>
                            <p>Yes, Clash Marker will only be able to read viewpoint reports that are generated by the Clash Marker Navisworks Plug-in. This is mandatory as the plug-in allows additional information to be included so that Clash Marker can carry out its proper functionality.</p>
                        </div>
                        <div className={isMobile ? "faq-item-container-mobile" : "faq-item-container-desktop"}>
                            <h4>I ran clashes/viewpoints for Revit in Clash Marker and it says a file was generated. Where did it end up?</h4>
                            <p>The Clash Marker Configuration file (.cmc) is put into the same place that your clash reports were imported in from. Wherever you have your clash reports in your files, that is where the .cmc file will be generated. You can now use import this file into the Clash Marker Revit plug-in for marking.</p>
                        </div>
                        <div className={isMobile ? "faq-item-container-mobile" : "faq-item-container-desktop"}>
                            <h4>My clashes/viewpoints aren't coming in correctly when using a custom origin/rotation. What's wrong?</h4>
                            <p>It is important to triple check that your transforms are correct and that you are correctly inputting them into Clash Marker. Our Clash Marker tips and tricks video <a target="_blank" rel='noreferrer' href={"https://youtu.be/cy02Zu50n3Y"}>here</a> can help sort out most issues.</p>
                        </div>
                    </Collapsible>
                    <Collapsible trigger={<div className={isMobile ? "faq-product-container-mobile" : "faq-product-container-desktop"}><div style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',}}><img alt="Mindful Logo" className={isMobile ? "faq-icon-mobile" : "faq-icon-desktop"} src={mindfulLogo}/><h3>Mindful</h3></div><img id={isMobile ? "drop-down-arrow-two-mobile" : "drop-down-arrow-two"} alt="Downward Facing Arrow" src={downArrow} onClick={()=>{rotateArrow(2)}}/></div>} easing={"ease-in-out"}>
                        <div className={isMobile ? "faq-item-container-mobile" : "faq-item-container-desktop"}>
                            <h4>Where is Mindful available?</h4>
                            <p>Mindful is available to download for free on the IOS App store <a href="https://apps.apple.com/us/app/mindful/id1637254506">here</a>.</p>
                        </div>
                        <div className={isMobile ? "faq-item-container-mobile" : "faq-item-container-desktop"}>
                            <h4>Can Mindful interface with my other calendars?</h4>
                            <p>As of right now, Mindful cannot integrate with other common calendars like Outlook, Google Calendar, and Apple Calendar. The TPS Team hopes to bring these features in a future update for your users</p>
                        </div>
                        <div className={isMobile ? "faq-item-container-mobile" : "faq-item-container-desktop"}>
                            <h4>Is there an easy way to share a task with someone in Mindful?</h4>
                            <p>Yes! By holding down on the task on the home screen and opening its information pop-up, you can click the underlined text at the bottom of the window labeled <strong>Invite a friend</strong>.</p>
                        </div>
                    </Collapsible>
                </div>
                <footer id={isMobile ? "footer-container-mobile" : "footer-container-desktop"}>
                    {!isMobile && <SiteMap/>}
                </footer>
            </div>
        </div>
    )
}

export default FAQ