import React from 'react';
import {useEffect, useState} from 'react';
import {useMediaQuery} from 'react-responsive';
import {motion, useAnimation} from 'framer-motion';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4'

//Icons
import { HiMenu } from 'react-icons/hi';

//CSS
import '../css/Legal.css'

//Images
import initialsLogo from '../images/TPSLogo+Initials.png'
import SiteMap from '../Components/SiteMap';

//Components
import MobileNavigationWindow from '../Components/MobileNavigationWindow';

function Legal() {

    const navModalAnim = useAnimation();
    const [navModal, setNavModal] = useState(false);
    const [termsVisible, setTermsVisible] = useState(true);
    const [aupVisible, setAupVisible] = useState(false);
    const [ppVisible, setPpVisible] = useState(false);
    const [cookieVisible, setCookieVisible] = useState(false);
    ReactGA.initialize("G-SZLZLTXLRV");
    
    useEffect(()=>{

        ReactGA.send({
            hitType: "pageview",
            page: "/",
            title: "Legal"
        });

        navModalAnim.start("visible");

        termlyEmbed(document, 'script', 'termly-jssdk')

    }, [navModalAnim]);

    const isMobile = useMediaQuery({
        query: '(max-width: 700px)'
    })

    const naval = {visible: {opacity: [0,1]}, hidden: {opacity: [1, 0], transition: {duration: 0.5}}}

    function showHideModal(){
        if(navModal){
            navModalAnim.start("hidden").then(()=>{
                setNavModal(false);
            });
        }
        else{
            setNavModal(true);
            navModalAnim.start({x: 500});
        }
    }

    function selectLegalDoc(id){
        switch(id){
            case 0:
                setTermsVisible(true);
                setAupVisible(false);
                setPpVisible(false);
                setCookieVisible(false);
                break;
            case 1:
                setTermsVisible(false);
                setAupVisible(true);
                setPpVisible(false);
                setCookieVisible(false);
                break;
            case 2:
                setTermsVisible(false);
                setAupVisible(false);
                setPpVisible(true);
                setCookieVisible(false);
                break;
            case 3:
                setTermsVisible(false);
                setAupVisible(false);
                setPpVisible(false);
                setCookieVisible(true);
                break;
            default:
                setTermsVisible(true);
                setAupVisible(false);
                setPpVisible(false);
                setCookieVisible(false);
        }
    }

    function termlyEmbed(d, s, id) {
        var js, tjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = "https://app.termly.io/embed-policy.min.js";
        tjs.parentNode.insertBefore(js, tjs);
    }

    return (
        <div>
            <header>
                {!isMobile && 
                    <nav className={"nav-desktop"}>
                        <Link to={"/"} id="nav-logo-desktop">
                            <img alt="Transparent Software Logo" src={initialsLogo}/>
                        </Link>
                        <div id={"nav-links-desktop"}>
                            <motion.a whileHover={{color: ['#000000', '#508B68'], y: [0, -2]}} href="/#explore"><p>Our Products</p></motion.a>
                            <motion.a whileHover={{color: ['#000000', '#508B68'], y: [0, -2]}} href="/#whatweoffer"><p>Services</p></motion.a>
                            <motion.a whileHover={{color: ['#000000', '#508B68'], y: [0, -2]}} href="/#whatwedo"><p>What We Do</p></motion.a>
                            <motion.a whileHover={{color: ['#000000', '#508B68'], y: [0, -2]}} href="/contactus"><p>Contact Us</p></motion.a>
                            <Link to={"/store"} id="nav-contact-desktop">
                                <motion.button id="nav-contact-button" whileHover={{backgroundColor: '#FFBE55'}}>
                                    <p style={{color: "#F2F4F3"}}>Shop</p>
                                </motion.button>
                            </Link>
                        </div>
                    </nav>
                }
                {isMobile && 
                    <nav className={"nav-mobile"}>
                        <Link to={"/"} id="logo-link-container">
                            <img src={initialsLogo} alt="Transparent Software Logo" id="nav-logo-mobile"/>
                        </Link>
                        <HiMenu id={"hamburger-icon-mobile"} size={25} onClick={()=>showHideModal()}/>
                    </nav>
                }
            </header>
            {isMobile && navModal && 
                <MobileNavigationWindow naval={naval} navModalAnim={navModalAnim}/>
            }
            <div className={isMobile ? "legal-parent-container-mobile" : "legal-parent-container"}>
                <h2 id="help-form-header" style={{fontSize: isMobile ? "6vw" : undefined}} >Transparent Software <span style={{color: '#78A48A'}}>Legal</span></h2>
                <div id={isMobile ? "legal-docs-selector-mobile" : "legal-docs-selector-desktop"}>
                    <div onClick={()=>{selectLegalDoc(0);}} className={isMobile ? "legal-title-container-mobile" : "legal-title-container-desktop"}>
                        {termsVisible && <motion.div animate={{opacity: [0, 0.5], transition:{duration: 0.5}}} className='gradient-container'></motion.div>}
                        <h3 className={isMobile ? "legal-title-mobile" : "legal-title-desktop"}>
                            Terms and Conditions                        
                        </h3>
                    </div>
                    <div onClick={()=>{selectLegalDoc(1);}} className={isMobile ? "legal-title-container-mobile" : "legal-title-container-desktop"}>
                        {aupVisible && <motion.div animate={{opacity: [0, 0.5], transition:{duration: 0.5}}} className='gradient-container'></motion.div>}
                        <h3 className={isMobile ? "legal-title-mobile" : "legal-title-desktop"}>
                            Acceptable Use Policy
                        </h3>
                    </div>
                    <div onClick={()=>{selectLegalDoc(2);}} className={isMobile ? "legal-title-container-mobile" : "legal-title-container-desktop"}>
                        {ppVisible && <motion.div animate={{opacity: [0, 0.5], transition:{duration: 0.5}}} className='gradient-container'></motion.div>}
                        <h3 className={isMobile ? "legal-title-mobile" : "legal-title-desktop"}>
                            Privacy Policy
                        </h3>
                    </div>
                    <div onClick={()=>{selectLegalDoc(3);}} className={isMobile ? "legal-title-container-mobile" : "legal-title-container-desktop"}>
                        {cookieVisible && <motion.div animate={{opacity: [0, 0.5], transition:{duration: 0.5}}} className='gradient-container'></motion.div>}
                        <h3 className={isMobile ? "legal-title-mobile" : "legal-title-desktop"}>
                            Cookie Policy
                        </h3>
                    </div>
                </div>
                <div id={isMobile ? "legal-document-container-mobile" : "legal-document-container-desktop"}>
                    <div name="termly-embed" data-id={termsVisible ? "b58f5282-8de0-4f2a-975c-36fdbe76f5d3" : aupVisible ? "a542dbfb-e972-410b-99d6-bd1d82d6b102" : ppVisible ? "1b95c11d-349b-4e53-80f0-2af2ec8df833" : "1c112b57-2396-4f33-90c0-b52b8c8e43b5"}></div>
                </div>
                <footer id={isMobile ? "footer-container-mobile" : "footer-container-desktop"}>
                    <SiteMap isMobile={isMobile}/>
                </footer>
            </div>
        </div>
    )
}

export default Legal